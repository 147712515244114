<template>
    <div class="vx-row panel_member">

        <MbrHeader ref="MbrHeader" v-bind:uid="$route.params.uid" v-on:refresh="update()"/>

        <div class="vx-col w-full sm:w-2/2 lg:w-3/3 mb-base">
                
            <vx-card>
                <vs-tabs alignment="fixed">
                    <vs-tab label="Séance(s)" icon-pack="feather" icon="icon-menu">
                        <br/>
                        <div class="tab-text">
                            <div class="flex flex-wrap">
                                <div class="w-full sm:w-2/2 md:w-2/2 lg:w-2/2 xl:w-2/2 bg-grid-color">
                                    <table class="info_leger_credit">
                                        <tr>
                                            <td>Groupe</td>
                                            <td>Restante(s)</td>
                                            <td>Utilisé(s)</td>
                                        </tr>
                                        <tr v-for="(item, index) in item_list" :key="item_list[index].uid" class="cel">
                                            <td class="font-semibold">{{ item_list[index].name }}</td>
                                            <td>{{ item_list[index].credit }}</td>
                                            <td>{{ item_list[index].used }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <br/>
                        </div>
                    </vs-tab>
                    <vs-tab label="Informations" icon-pack="feather" icon="icon-user" @click="changeTab">
                        <MbrFiche v-bind:uid="$route.params.uid" ref="MbrFiche" v-on:refresh="printMsg" />
                    </vs-tab>
                    <vs-tab label="Mollie" icon-pack="feather" icon="icon-credit-card">
                        <br/>
                        <div class="tab-text">
                            <template v-if="mollie_info">
                                <h2>Mandat enregistré(s):</h2>
                                <vs-divider />
                                <vs-list v-for="item in mollie_info.mandate" :key="item.id">
                                    <vs-list-item :title="item.id" :subtitle="item.signatureDate" >
                                        <!--{{ item.details.consumerAccount }}--><!-- <vs-icon icon="cancel" size="small" color="red" class="cursor pl-4" v-on:click="deleteMollieMandate(item.id)"></vs-icon>-->
                                    </vs-list-item>
                                </vs-list>
                                <br/>
                                <br/>
                                <h2>Prélévement enregistré(s):</h2>
                                <vs-divider />
                                <vs-row v-for="item in mollie_info.sub" :key="item.id" class="pt-4">
                                    <!-- <vs-list-item :title="item.id+' - '+item.status" :subtitle="item.description"> -->
                                        <!-- <vs-row> -->
                                            <vs-col vs-justify="center" vs-align="center" vs-w="3" vs-xs="12" vs-md="6" class="pr-4">
                                                <p><strong>{{item.id+' - '+item.status}}</strong></p>
                                                <p>{{item.description}}</p>
                                            </vs-col>
                                            <vs-col vs-justify="center" vs-align="center" vs-w="3" vs-xs="12" vs-md="6" class="pr-4">
                                                <div>
                                                    <b>Début</b>: {{ item.startDate }}<br/>
                                                    <template v-if="item.nextPaymentDate">
                                                        <b>Suivant</b>: {{ item.nextPaymentDate }}<br/>
                                                    </template>
                                                </div>
                                            </vs-col>
                                            <vs-col vs-justify="center" vs-align="center" vs-w="3" vs-xs="12" vs-md="6" class="pr-4">
                                                <div>
                                                    <b>Montant</b>: {{ item.amount.value }} {{ item.amount.currency }}<br/>
                                                    <b>Répétition</b>: {{ item.interval }} / {{ item.times }}
                                                </div>
                                            </vs-col>
                                            <vs-col vs-justify="center" vs-align="center" vs-w="3" vs-xs="12" vs-md="6" class="pr-4">
                                                <vs-button v-if="item.status === 'active'" color="danger" type="filled" v-on:click="deleteMollieSub(item.id)"> Supprimer le mandat </vs-button>
                                                <p v-else-if="item.status === 'canceled'">Ce mandat a été annulé.</p>
                                                <p v-else>Ce mandat ne peut pas être annulé.</p>
                                            </vs-col>
                                        <!-- </vs-row> -->
                                    <!-- </vs-list-item> -->
                                </vs-row>
                            </template>
                            <template v-else >
                                <h2>Chargement mollie information ...</h2>
                            </template>
                        </div>
                    </vs-tab>
                    <vs-tab label="Documents" icon-pack="feather" icon="icon-file-text">
                        <br/>
                        <div class="tab-text">
                            <div style="text-align:center;">
                                <Upload ref="Upload" :url="url_upload" v-on:refresh="update" />
                            </div>
                            <vs-divider>Liste fichier(s) membre:</vs-divider>
                            <br/>
                            <ul v-for="(item, key, index) in list_doc" :key="index" style="text-align:left;">
                                <li>
                                    <div>
                                        <a target="#" v-on:click="viewFile(item)" class="cursor mr-4" >{{ item }}</a>
                                        
                                        <!-- <a target="#" v-on:click="deleteFile(item)" class="cursor float-right ml-4">
                                            <vs-icon icon="cancel" size="small" color="red" class="cursor"></vs-icon>
                                        </a> -->
                                    </div>
                                </li>
                            </ul>
                            <template v-if="list_doc.length<1">
                                <br/>
                                <p>Aucun document disponible !</p>
                            </template>
                        </div>
                    </vs-tab>
                </vs-tabs>
                </vx-card>
                <br/>

                <!-- HISTO VENU -->
                <vs-row vs-type="flex" vs-justify="space-between" vs-align="center">
                    <vs-col vs-w="1">
                        <vs-button color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" class="dateBtn" v-on:click="graphChangeYear(-1)"></vs-button>
                    </vs-col>
                    <vs-col vs-w="10">
                        <h3 class="text-center">{{ graph_year }}</h3>
                        <vue-apex-charts ref="bar_resa" type="bar" height="200" :options="resa_columnChart.chartOptions" :series="resa_columnChart.series"></vue-apex-charts>
                    </vs-col>
                    <vs-col vs-w="1">
                        <vs-button color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" class="dateBtn" v-on:click="graphChangeYear(1)"></vs-button>
                    </vs-col>
                </vs-row>
                <br/>

                <!-- HISTORIQUE -->
                <vx-card>
                    <div class="text-center">
                        <ul class="con-s text-center">
                            <li>
                                <label>Facturation</label>
                                <vs-switch color="success" v-model="histo_filtre_facture" v-on:change="filtreChange()"/>
                            </li>
                            <li>
                                <label>Commentaire</label>
                                <vs-switch color="warning" v-model="histo_filtre_commentary" v-on:change="filtreChange()"/>
                            </li>
                            <li>
                                <label>Réservation</label>
                                <vs-switch color="dark" v-model="histo_filtre_booking" v-on:change="filtreChange()"/>
                            </li>
                        </ul>
                    </div>
                </vx-card>
                <br/>

                <!-- HISTORIQUE MEMBRE -->
                <listDate ref="ListHisto" v-bind:reverse="1" v-bind:sep="true" >
                    <template v-slot:item="item">

                        <!-- reservation membre -->
                        <template v-if="item.item.data.type=='resa'" >
                            <vx-card 
                                card-background = "white" 
                                content-color   = "black" 
                                class = "cursor" 
                                v-on:click = "clickHisto(item.item.data)">
                                <span>
                                    <feather-icon icon="GitCommitIcon" svgClasses="stroke-current w-4 h-4 histo_icon"/>
                                    <span v-html="item.item.data.content"></span>
                                    <span class="center">
                                        <template v-if="!item.item.data.venu">
                                            <span style="padding-left:10px;"><b style="color:red;">Pas présent</b></span>
                                        </template>
                                        <template v-if="item.item.data.bilan">
                                            <span style="padding-left:10px;"><b style="color:green;">Bilan effectué</b></span>
                                        </template>
                                    </span>
                                </span>
                                <span style="float:right;">{{ GetFullDate(item.item.data.date) }}</span>
                            </vx-card>
                        </template>

                        <!-- commentaire -->
                        <template v-if="item.item.data.type=='com'" >
                            <vx-card 
                                card-background = "#FCF7DE" 
                                content-color   = "#000" 
                                class = "cursor" 
                                v-on:click = "clickHisto(item.item.data)">
                                <span>
                                    <feather-icon icon="MessageSquareIcon" svgClasses="text-black stroke-current w-4 h-4 histo_icon" />
                                    <span v-html="item.item.data.content" ></span>
                                </span>
                                <span style="float:right;">{{ GetFullDate(item.item.data.date) }}</span>
                            </vx-card>
                        </template>

                        <!-- facture -->
                        <template v-if="item.item.data.type=='fa'" >
                            <vx-card 
                                card-background = "#DEFDE0" 
                                content-color   = "#000" 
                                class = "cursor" 
                                v-on:click = "clickHisto(item.item.data)">
                                <span>
                                    <feather-icon icon="ShoppingCartIcon" svgClasses="text-black stroke-current w-4 h-4 histo_icon" />
                                    <span>{{ item.item.data.content }} de <b>{{ item.item.data.ttc.toFixed(devise.nb_decimal) }}</b> {{ devise.symbol }}</span>
                                </span>
                                <span style="float:right;">{{ GetFullDate(item.item.data.date) }}</span>
                            </vx-card>
                        </template>


                        <template v-if="item.item.data.type=='re'" >
                            <vx-card 
                                card-background = "#DEF3FD" 
                                content-color   = "#000" 
                                class = "cursor" 
                                v-on:click = "clickHisto(item.item.data)">
                                <span>
                                    <feather-icon icon="ShoppingCartIcon" svgClasses="text-black stroke-current w-4 h-4 histo_icon" />
                                    <span >{{ item.item.data.content }} de <b>{{ item.item.data.ttc.toFixed(devise.nb_decimal) }}</b> {{ devise.symbol }}</span>
                                </span>
                                <span style="float:right;">{{ GetFullDate(item.item.data.date) }}</span>
                            </vx-card>
                        </template>

                        <template v-if="item.item.data.type=='av'" >
                            <vx-card 
                                card-background = "#FDDFDF" 
                                content-color   = "#000" 
                                class = "cursor" 
                                v-on:click = "clickHisto(item.item.data)">
                                <span>
                                    <feather-icon icon="ShoppingCartIcon" svgClasses="text-black stroke-current w-4 h-4 histo_icon" />
                                    <span>{{ item.item.data.content }} de <b>{{ item.item.data.ttc.toFixed(devise.nb_decimal) }}</b> {{ devise.symbol }}</span>
                                </span>
                                <span style="float:right;">{{ GetFullDate(item.item.data.date) }}</span>
                            </vx-card>
                        </template>

                        <template v-if="item.item.data.type=='lcl'" >
                            <vx-card 
                                card-background = "#32CD32" 
                                content-color   = "#000" >
                                <span>
                                    <feather-icon icon="MapPinIcon" svgClasses="text-black stroke-current w-4 h-4 histo_icon" />
                                    <span>Inscription avec origine </span><b>{{ item.item.data.content }}</b>
                                </span>
                                <span style="float:right;">{{ GetFullDate(item.item.data.date) }}</span>
                            </vx-card>
                        </template>
                        <br/>
                    </template>

                    <!-- HISTORIQUE VIDE -->
                    <template v-slot:empty>
                        <br/>
                        <h3 style="text-align:center;">Aucun historique</h3>
                    </template>
                </listDate>
        </div>
    </div>
</template>



<style lang="scss">
.panel_member
{
    .info_leger_credit
    {
        td{
            width:150px;
        }
        .cel td:nth-child(2){
            color:red;
        }
        tr{
            height:50px;
        }  
    }
    .histo_icon
    {
        margin-right:20px;
    }
    .cursor
    {
        cursor:pointer;
    }
    ul{
        text-align:center;
    }
    li{
        display: inline-block;
        margin:10px;
        margin-left:20px;
        margin-right:20px;

        button{
            text-align:center;
            margin:auto;
        }
    }
}
</style>

<script>
import Utils from '@/assets/utils/utils';
import Fdate from '@/assets/utils/fdate';
import Credit from '@/assets/utils/credit';

import Member  from '@/database/models/member';
//import Terminal from '@/database/models/terminal';
import Invoice from '@/database/models/invoice';
import Machine from '@/database/models/machine';
import Booking from '@/database/models/booking';
import Commentary from '@/database/models/commentary';
import Item from '@/database/models/item';
import Channel from '@/database/models/channel';
import ChannelLink from '@/database/models/channel_link';

import autoRefresh from '@/assets/utils/autoRefresh'
import listDate from '@/components/app/listDate.vue'
import Upload from '@/components/app/upload.vue'
import MbrHeader from '@/components/member/header.vue'
import MbrFiche from '@/components/member/ufiche.vue'

import VueApexCharts from 'vue-apexcharts'


export default {
    components:{
        listDate,
        Upload,
        MbrHeader,
        MbrFiche,
        VueApexCharts,
    },
    data(){
        return{
            item_credit:[],
            member:null,
            history:[],
            history_buffer:[],
            histo_filtre_facture   : true,
            histo_filtre_commentary: true,
            histo_filtre_booking   : true,

            list_doc:[],
            url_upload:'studio/cloudmember/?',

            mollie_info:null,

            admin:false,

            item_list:[],
            list_machine:[],


            resa_columnChart: {
                series: [{
                    name: 'Réservation',
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }],
                chartOptions:
                {
                    colors: [ '#6EB0D4' ],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            endingShape: 'rounded',
                            columnWidth: '55%',
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },

                    xaxis: {
                        categories: Fdate.tab_month,
                    },
                    yaxis: {
                        title: {
                        show: false,
                        text: ""
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function(val){
                                return val + " réservation(s)"
                            }
                        }
                    }
                }
            },
            graph_year : (new Date()).getFullYear(),

            devise: {}
        }
	},
	mounted:function()
	{
        this.$srvApi.Req('get', 'public/currency')
        .then(( rep )=>{
            this.devise = rep.data;
        })
        //autoRefresh
		autoRefresh.SetCallback(()=>{
            this.RoleAdmin()
            loadMember(this)
            

        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            autoRefresh.refresh()
        }
    },
    methods:{
        StrCapital:function(str){
            return Utils.StrCapital(str);
        },
        GetFullDate:function(date)
        {
            return Fdate.getFullDate(date);
        },
        changeTab:function()
        {
            loadMember(this)
        },
        RoleAdmin()
        {
            this.admin = false;
                  //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            Member.getMemory(uid, (mbr)=>
            {
                this.admin = mbr.type.toLowerCase() == "a";
            });
        },
        clickHisto:function( data )
        {
            //console.log( data.url )
            if(data)
            if(data.url)
                this.$router.push(data.url);
        },
        filtreChange:function()
        {
            this.history = [];
            for( var i=0; i<this.history_buffer.length; i++)
            {
                var buffer = this.history_buffer[i];
                var ok = true;

                //facture
                if(buffer.data.type == 'fa' || buffer.data.type == 're' || buffer.data.type == 'av')
                if(!this.histo_filtre_facture )
                    ok=false;

                //commentaire
                if(buffer.data.type == 'com')
                if(!this.histo_filtre_commentary )
                    ok=false;

                //booking
                if(buffer.data.type == 'resa')
                if(!this.histo_filtre_booking )
                    ok=false;

                //Ajout
                if(ok)
                    this.history.push(buffer);
            }

            //evite un bug bizar lors de certain refresh
            if(this.$refs.ListHisto.addTabMonth)
                this.$refs.ListHisto.addTabMonth( this.history )
        },


        update()
        {
            loadMember(this)
        },

        viewFile:function( file )
        {
            if(file)
                this.$router.push('/admin/file/'+ encodeURI(btoa('studio/cloudmember/'+this.$route.params.uid+'/'+file)) );
        },
        deleteFile( file )
        {
            if( file )
            this.$srvApi.Req('delete', 'studio/cloudmember/'+this.$route.params.uid+'/'+file)
            .then(()=>{
                autoRefresh.refresh()
            });
        },



        deleteMollieMandate( uid )
        {
            let uid_member = this.$route.params.uid;
            if( window.confirm("Voulez-vous vraiment annuler ce mandat ?") )
            this.$srvApi.Req('delete', 'mollie/'+uid_member+'/mandate/'+uid )
            .then(( rep )=>
            { 
                //console.log( rep );
                if( rep.status == "success")
                {
                  alert('Mandat supprimé');
                  loadMember(this);
                }
            })
        },
        deleteMollieSub( uid )
        {
            let uid_member = this.$route.params.uid;
            if( window.confirm("Voulez-vous vraiment annuler ce prélévement ?") )
            this.$srvApi.Req('delete', 'mollie/'+uid_member+'/sub/'+uid )
            .then(( rep )=>
            {
                //console.log( rep );
                if( rep.status == "success")
                {
                  alert('Sub supprimé');
                  loadMember(this);
                }
            })
        },

        printMsg()
        {
            alert('Modification enregistrée');
        },



        graphChangeYear( value )
        {
            this.graph_year += value;
            BuildGraph( this, this.$route.params.uid );
        }

    }
};



function loadMember(root)
{
    let uid_member = root.$route.params.uid;
    Member.getMemory( uid_member, async function(member)
    {
        //data
        root.member   = member;
        root.photoURL = root.$srvApi.buildUrl('studio/member/'+member.uid+'/photo');

        //Build crédit
        root.item_credit = await Credit.getCredit( uid_member );

        // on récupere le nom des machines
        var list_aux=[];
        let c = await Machine.AwaitGetAllMemory();
        for (let i in root.item_credit){
            let aux = i;
            aux = aux.charAt(0).toLowerCase() + aux.slice(1)
            let nom ; 
            if( i == "global"){
                nom="Global";
            } else if( c[aux]!=undefined){
                nom = c[aux].name;
            } else{
                nom=i;
            }
            let list ={
                name:nom,
                credit:root.item_credit[i].credit,
                used:root.item_credit[i].used,
                uid:i,
            }

            list_aux.push(list)
        }
        root.item_list=list_aux;
        
        


        //-----------------------------------------------------------
        //Build History
        BuildHistory( uid_member, function(final2)
        {
            root.history_buffer = final2;
            root.history        = final2;
            root.filtreChange()
        });

        //-----------------------------------------------------------
        //Load fichier membre
        root.url_upload = 'studio/cloudmember/'+member.uid
        root.$srvApi.Req('get', 'studio/cloudmember/'+member.uid)
        .then(( rep )=>{ 
            if(rep.status=="success") {
                root.list_doc = rep.data
            }
        })

        //-----------------------------------------------------------
        //Load fichier membre
        if( root.$refs.MbrFiche != undefined )
            root.$refs.MbrFiche.refresh();

        //-----------------------------------------------------------
        //Build graph histo réservation
        BuildGraph(root, uid_member);

    });

    //Mollie load
    MollieLoad( root, uid_member );
}


function BuildGraph( root, uid_member )
{
    root.resa_columnChart.series[0].data[0] = 0;
    root.resa_columnChart.series[0].data[1] = 0;
    root.resa_columnChart.series[0].data[2] = 0;
    root.resa_columnChart.series[0].data[3] = 0;
    root.resa_columnChart.series[0].data[4] = 0;
    root.resa_columnChart.series[0].data[5] = 0;
    root.resa_columnChart.series[0].data[6] = 0;
    root.resa_columnChart.series[0].data[7] = 0;
    root.resa_columnChart.series[0].data[8] = 0;
    root.resa_columnChart.series[0].data[9] = 0;
    root.resa_columnChart.series[0].data[10]= 0;
    root.resa_columnChart.series[0].data[11]= 0;
    Booking.dataForKey( uid_member, function(book_data, end)
    {
        let d = new Date(book_data.date);

        if(d.getFullYear() == root.graph_year)
            root.resa_columnChart.series[0].data[ d.getMonth() ]++;
        end();
    })
    .then(()=>{
        root.$refs.bar_resa.refresh();
    })
}

//-----------------------------------------------------------------------------
//Build Crédit
/*function BuildCredit( uid_member, callback )
{
    Credit.getItemParse( uid_member, function( list_item_used)
    {
        //utilisation des credit item
        Credit.itemCreditUse(uid_member, function( item_credit_use)
        {
            //console.log( list_item_used );
            //console.log( item_credit_use );
            //console.log( item_credit_use[null] );

            //Compacter les données
            var tab_final = [];
            for( var i=0; i<list_item_used.length; i++ )
            if( list_item_used[i].credit!=0)
            {
                var buffer = list_item_used[i];
                buffer.used = 0;
                if(item_credit_use[ buffer.uid ])
                    buffer.used = item_credit_use[ buffer.uid ];
                tab_final.push(buffer);
            }
            //add special (si aucune facture)
            if( item_credit_use['global'] )
                tab_final.push({
                    credit: 0,
                    used  : item_credit_use['global'],
                    date  : null,
                    groupe: null,
                });

            //Tableau des crédit
            var date_actu = (new Date()).getTime();
            var list_credit = {};
            for( var p=0; p<tab_final.length; p++)
            {
                buffer = tab_final[p];
                var credit_add = buffer.credit;
                var groupe = 'global';

                //Expiration
                var date_ext = (new Date(Invoice.getDateFin( buffer.expire, buffer.date ))).getTime();
                if( date_actu > date_ext )
                    credit_add = buffer.used;
                
                //groupe
                if(buffer.groupe)
                    groupe = buffer.groupe;

                if(!list_credit[groupe])
                    list_credit[groupe] = {
                        groupe:groupe,
                        credit:0,
                        used:0
                    };
                list_credit[groupe].credit += credit_add;
                list_credit[groupe].used   += buffer.used;
            }
            
            if(callback)
                callback( list_credit );
        });
    });
}
*/




//-----------------------------------------------------------------------------
function BuildHistory( uid_member, callback )
{
    Invoice.dataForKey( uid_member, function(invoice_data, end)
    {
        //add item
        Item.getTabAllMemoryKey( invoice_data.uid, function( item_list)
        {
            //mode de payment
            let paye = [];
            paye.push({name:'CB'         ,value: invoice_data.pay_cb   });
            paye.push({name:'Espéce'     ,value: invoice_data.pay_money});
            paye.push({name:'Chéque'     ,value: invoice_data.pay_check});
            paye.push({name:'Différé'    ,value: invoice_data.pay_dif  });
            paye.push({name:'Prélévement',value: invoice_data.pay_prele});

            let ttc = invoice_data.pay_cb+invoice_data.pay_money+invoice_data.pay_check+invoice_data.pay_dif+invoice_data.pay_prele;
            if(invoice_data.type=="re")
                ttc = invoice_data.pay_dif*-1

            //final model
            end({
                date: invoice_data.createAt,
                data:{
                    uid     : invoice_data.uid,
                    type    : invoice_data.type,
                    date    : invoice_data.createAt,
                    content : Invoice.getTypeName(invoice_data),
                    item    : item_list,
                    payement: paye,
                    ttc     : ttc,
                    url     : '/admin/invoice/'+ invoice_data.uid,
                }
            });
        });
    })
    .then(function(list_invoice_data)
    {
        //-----------------------------------------------------------
        //Get Commentaire
        Commentary.dataForKey( uid_member, function(com_data, end)
        {
            end({
                date: com_data.createAt,
                data: {
                    uid      : com_data.uid,
                    type     : 'com',
                    date     : com_data.createAt,
                    content  : com_data.commentary,
                    url      : '/admin/commentary/'+ com_data.uid,
                }
            });
        })
        .then(function(list_com_data)
        {
            //-----------------------------------------------------------
            Booking.dataForKey( uid_member, function(book_data, end)
            {
                Machine.getMemory( book_data.machine, function(machine)
                {
                    let cbuf  = '<span style="color:'+Booking.getColor(book_data.type)+'">'+Utils.StrCapital(Booking.getTypeName( book_data.type ))+'</span>';
                    //add name machine
                    if(machine)
                        cbuf +=' '+machine.name;

                    end({
                        date : book_data.date,
                        data : {
                            uid      : book_data.uid,
                            type     : 'resa',
                            date     : book_data.date,
                            content  : cbuf,
                            url      : '/admin/bookingview/'+book_data.uid,
                            bilan    : book_data.bilan,
                            venu     : book_data.venu,
                        }
                    });
                });
            })
            .then(function(list_book_data)
            {
                let final = list_invoice_data.concat(list_com_data,list_book_data);

                //Get list pro
                Channel.getTabAllMemory( (prov_list)=>
                {
                    //Provenance
                    ChannelLink.getTabAllMemoryKey( uid_member,( list_channel_link)=>
                    {
                        for( var lcl=0; lcl<list_channel_link.length; lcl++ )
                        {
                            let origine = '?';
                            for(var i=0; i<prov_list.length;i++)
                            if(prov_list[i].uid == list_channel_link[lcl].channel)
                                origine = prov_list[i].name;

                            //push final
                            final.push({
                                date: list_channel_link[lcl].createAt,
                                data: {
                                    uid      : list_channel_link[lcl].uid,
                                    type     : 'lcl',
                                    date     : list_channel_link[lcl].createAt,
                                    content  : origine,
                                    url      : "#",
                                }
                            })
                        }

                        //-----------------------------------------------------------
                        //End

                        //save
                        callback( final );
                    })
                });

            });
        });
    });
}



//-----------------------------------------------------------------------------
function MollieLoad( root, uid_member )
{
    root.$srvApi.Req('get', 'mollie/'+uid_member+'/get')
    .then(( rep )=>
    { 
        if( rep.status != "success")
            alert('Mollie err: '.rep.message);

        let mollie_data = {};
        try {
            mollie_data = JSON.parse(rep.data);
        } catch (error) {
            //error
        }
        
        //set mollie data
        root.mollie_info = mollie_data;
    })
}

</script>